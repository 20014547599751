import { RxStompConfig } from '@stomp/rx-stomp';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

export const myRxStompConfig: RxStompConfig = {
  // Which server?
  brokerURL: environment.webSocket,

  // Headers
  // Typical keys: login, passcode, host
  connectHeaders: {
    Authorization : `Bearer ${AuthService.getAccessToken}`
  },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: 0, // Typical value 0 - disabled
  heartbeatOutgoing: 1000, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 500 (500 milli seconds)
  reconnectDelay: 20000,

  // Will log diagnostics on console
  // It can be quite verbose, not recommended in production
  // Skip this key to stop logging to console
  debug: (msg: string): void => {
  },
  
};
