// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//  node --max_old_space_size=8048 node_modules/@angular/cli/bin/ng s

export const environment = {
  production: true,
  appVersion: 'v1',
  //localEndPoints
//  apiEndpoint: 'https://devapi.nrichlearning.in',
//  authUrl: 'https://devapi.nrichlearning.in',
 // webSocket: 'wss://devapi.nrichlearning.in/ws',
 // viduUrl: 'https://servicenewui.nrichlearning.in',
 // zoomUrl: 'https://zoom.nrichlearning.in',
 // webHookUrl:'https://devapi.nrichlearning.in/leads',
  //socialConnectEndpoint: 'https://devsocial.nrichlearning.in',
  apiEndpoint: 'https://devapi.nrichlearning.in',
  authUrl: 'https://devapi.nrichlearning.in',
  webSocket: 'wss://devapi.nrichlearning.in/ws',
  viduUrl: 'https://servicenewui.nrichlearning.in',
  zoomUrl: 'https://devclassroom.nrichlearning.in',
  webHookUrl:'https://devapi.nrichlearning.in/leads',
  socialConnectEndpoint: 'https://devsocial.nrichlearning.in',

  // TestingEndPoints
  // apiEndpoint: 'https://servicesnewui.profices.in',
  // authUrl: 'https://servicesnewui.profices.in',
  // webSocket: 'wss://servicesnewui.profices.in/ws',
  // viduUrl: 'https://servicesnewui.profices.in',
  // zoomUrl: 'https://engaje.profices.in',
firebase : {
    apiKey: "AIzaSyBCqhAfU6KVY8hg1mzUDn5ebiefplaoGcQ",
    authDomain: "nrichnotifications.firebaseapp.com",
    projectId: "nrichnotifications",
    storageBucket: "nrichnotifications.appspot.com",
    messagingSenderId: "244631308103",
    appId: "1:244631308103:web:5648306f7c7d8d0bc7ee62",
    measurementId: "G-ZMHWQ087S5",
    vapidKey: 'BA5KB40QVpG_VaAdQO2SoWDisUbwhPwTCO-In4mbYKORrsLfQOlP6oorVztGm3dnho7gO6i6J74uhA4ePHMEV9s',
  },

  //signupRoute: 'https://servicesnewui.profices.in', 
  
  GA_TRACKING_ID: 'AW-10881477506',
  faceBookPixelID: '827328441986530',
  accessToken:
    'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjpbeyJhdXRob3JpdHkiOiJBZG1pbiJ9XSwidXNlcl9uYW1lIjoicmFqYXQiLCJzY29wZSI6WyJyZWFkIiwid3JpdGUiXSwiaWQiOiJyYWphdCIsImV4cCI6MTYxNjk1NjQyNywiYXV0aG9yaXRpZXMiOlsiQWRtaW4iXSwianRpIjoiOTRmZDQyMGEtMTZhMS00NTI5LWE4MWEtN2ZlNjU3NmJjZTYzIiwiY2xpZW50X2lkIjoiJDJ5JDEyJGVVb3ZKdDN0VjJEeGZRRXNwYVB4Y3VjbVljUFlQQkdHb2pHb2lJMXZ0NE9pYzNoZm02Mnc2In0.b5S8uINW0c5PFoXAu0iXcwPkpZuV7yIGlXQJkwCYK1jgDyzrh1NYVFGVtE-kHNpuBR1MTwcfCDIpUEE2Lry38pI5C6LOEM_OOq0p6tIjcbk4F59Pj_jIcNjYHUFE2srgiU4LLnJaLMYbkSUNn-c6OYsFI9Tc02h22UXHGsTbGPSRpNciaDlZMOF_b7iOD5dj5ul7uZBznqMsMY1EVxS7pj48WIjd-3ILpRVWpfHCuxSLUi5pJ3DcLxKDpzZQcQz-j64D6r7PHM_yggk7WxoqCbsGWkpkAZ0YH1xsJ0IAVgtgliEYTipk9sVR2-jQ6-RITv0B5b336xcsd6IJH_WZLQ',
  //razorpayKeyId: 'rzp_live_2XAPgReaW7rTNj',
  // razorpayKeyId: 'rzp_test_SWugFL0SkG1hoU',
  bucketName: '',
  leaveUrl: 'https://learningnewui.profices.in/',
  apiKey: 'o1ogFMjPSsOj33pSNuE5Tw',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
