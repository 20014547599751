import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { Subscription, filter, interval } from 'rxjs';
import { LoaderService } from './loader.service';
import { AuthService } from './services/auth.service';
import { FirebaseService } from './services/firebase/firebase.service';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { LoaderComponent } from './common/loader/loader.component';
import { NgIf } from '@angular/common';
import { NotificationService } from './services/notification/notification.service';
import { environment } from 'src/environments/environment';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { InstituteService } from './services/institute/institute.service';
import { SkeletonLoaderComponent } from './common/skeleton-loader/skeleton-loader.component';
import { Location } from '@angular/common';
import { LastvisitedurlService } from './services/lastvisitedurl.service';

const firebaseApp = initializeApp(environment.firebase);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    NgIf,
    LoaderComponent,
    WhatsappComponent,
    SkeletonLoaderComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'nrichUI';
  subscription!: Subscription;
  message!: any;
  networkSpeed!: number;
  id: number = 1;
  intervalSubscriptioin!: Subscription;
  snacBarRef!: any;
  loaderSubscription!: Subscription;
  showLoader: boolean = false;
  appVersionObj!: { version: string; ishighPriority: boolean };
  isWhatsAppEnabled: boolean = false;
  isWhatsAppEnabledSub!: Subscription;
  isVisitedBefore: boolean = false;
  subDomainInstitute: number = 1;
  skeletonLoader: boolean = true;
  currentUrl!: string;
  previousUrl!: string;
  lastUrl!: string;
  constructor(
    public loaderService: LoaderService,
    private location: Location,
    private firebaseService: FirebaseService,
    private authService: AuthService,
    private instituteService: InstituteService,
    private notificationService: NotificationService,
    private snackBar: MatSnackBar,
    public router: Router,
    public lastvisitedurlService: LastvisitedurlService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit() {
    // code for last visited route starts
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if (this.currentUrl !== '/login') {
          this.previousUrl = this.lastUrl; // Store the last URL before updating
          this.lastUrl = this.currentUrl; // Update the last URL
        }
        this.lastvisitedurlService.setPreviousUrl(this.previousUrl);
      });

    //code for last visted route ends here
    this.subscribeToWhatsapp();
    this.subscribeToLoader();
    if (this.platformId === 'browser') {
      this.router.events
        .pipe(filter((event: any) => event instanceof NavigationEnd))
        .subscribe(() => {
          window.scroll(0, 0);
        });
      this.internetSpeedSubscription();
      this.noInteretConnectionListener();
      const currentUrl = window.location.href;
      if (
        currentUrl.includes('nrichlearning') ||
        currentUrl == 'http://localhost:4200/'
      ) {
        this.skeletonLoader = false;
      }

      const subdomain = this.authService.getSubDomain(currentUrl);
      const url = new URL(currentUrl);
      const path = url.pathname;

      if (path === '/') {
        if (
          subdomain !== 'nrichlearning' ||
          currentUrl !== 'http://localhost:4200/'
        ) {
          this.authService.getInstituteBySubDomain(subdomain).subscribe({
            next: (res: number) => {
              let instituteId = res;
              this.authService.subDomainInstitute.next(res);

              // Redirect to the institute-info page
              this.router
                .navigate([`/institute-info`], { queryParams: { id: res } })
                .then(() => {
                  // Use replaceState to prevent the root URL from being accessible via back button
                  this.location.replaceState(`/institute-info?id=${res}`);
                });

              this.instituteService
                .fetchInstitute(instituteId)
                .subscribe((data: any) => {
                  this.skeletonLoader = false;
                  localStorage.setItem(
                    'instituteDetail',
                    JSON.stringify(data.body)
                  );
                });
              localStorage.setItem('instituteId', String(instituteId));
            },
            error: (err) => {
              this.skeletonLoader = false;
              // TODO: Show proper error popup for on domain of failed
            },
          });
        } else {
          this.skeletonLoader = false;
          this.authService.subDomainInstitute.next(1);
        }
      } else {
        this.authService.getInstituteBySubDomain(subdomain).subscribe({
          next: (res: number) => {
            this.id = res;
            this.authService.subDomainInstitute.next(res);

            this.instituteService
              .fetchInstitute(this.id)
              .subscribe((data: any) => {
                this.skeletonLoader = false;
                localStorage.setItem(
                  'instituteDetail',
                  JSON.stringify(data.body)
                );
              });
          },
          error: (err) => {
            this.skeletonLoader = false;
            // TODO: Show proper error popup for on domain of failed
          },
        });
      }

      // Optional: Listen for route changes and prevent back navigation to root URL
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (
            event.url === '/' &&
            !currentUrl.includes('localhost') &&
            subdomain !== 'dev' &&
            subdomain !== 'stg' &&
            subdomain !== 'nrichlearning'
          ) {
            // Prevent back navigation to root URL
            this.router.navigate([`/institute-info`], {
              queryParams: { id: this.id },
            });
          }
        }
      });
      this.authService.subDomainInstitute.subscribe((data: any) => {
        this.subDomainInstitute = data;
      });

      this.subscription = this.authService.loggedInSubject.subscribe(
        (isLogin: boolean) => {
          if (isLogin) this.requestPermission();
          this.listen();
        }
      );

      this.currentUrl = this.location.path();
      if (this.currentUrl === '/login') {
        localStorage.removeItem('auth');
      }
      //this.subscribeToGT();
    }
  }

  requestPermission() {
    const messaging = getMessaging(firebaseApp);
    getToken(messaging, { vapidKey: environment.firebase.vapidKey }).then(
      (currentToken) => {
        if (currentToken) {
          this.firebaseService.saveToken(currentToken).subscribe();
        }
      }
    );
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      const data = payload.data;
      if (
        data &&
        +data?.['userId'] == AuthService.getUserId &&
        data?.['instituteId'] == AuthService.getInstituteId
      ) {
        this.notificationService.liveNotification.next(payload);
      }
    });
  }

  // subscribeToGT() {
  //   this.router.events.subscribe((event) => {
  //     if (event instanceof NavigationEnd) {
  //       gtag('config', 'G-YJ75Q4FMM1', {
  //         page_path: event.urlAfterRedirects,
  //       });
  //     }
  //   });
  // }

  subscribeToLoader() {
    this.loaderSubscription = this.loaderService.loading$.subscribe(
      (data: boolean) => {
        this.showLoader = data;
        //this.changeDetection.detectChanges();
      }
    );
  }

  internetSpeedSubscription() {
    const conn = (navigator as any).connection;
    this.intervalSubscriptioin = interval(5000).subscribe((data: any) => {
      this.networkSpeed = conn?.downlink;
      if (this.networkSpeed <= 0.2 && this.networkSpeed > 0) {
        if (!this.snacBarRef) {
          this.snacBarRef = this.snackBar
            .open(
              'You internet connection is slow. Please check your connection.',
              'OK',
              {
                duration: 20000,
                horizontalPosition: 'right',
                verticalPosition: 'bottom',
              }
            )
            .afterDismissed()
            .subscribe((data: any) => {
              this.snacBarRef = undefined;
            });
        }
      }
    });
  }

  noInteretConnectionListener() {
    window.addEventListener('offline', () => {
      this.snackBar.open('No Internet Connection !!', 'OK');
      window.addEventListener('online', () => {
        this.snackBar.open('You are online now !!', 'OK');
      });
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.intervalSubscriptioin) this.intervalSubscriptioin.unsubscribe();
    if (this.loaderSubscription) this.loaderSubscription.unsubscribe();
    if (this.isWhatsAppEnabledSub) this.isWhatsAppEnabledSub.unsubscribe();
    if (this.isWhatsAppEnabledSub) this.isWhatsAppEnabledSub.unsubscribe();
  }

  subscribeToWhatsapp() {
    this.isWhatsAppEnabledSub = AuthService.whatsappSubject$.subscribe(
      (isEnabled: boolean) => {
        this.isWhatsAppEnabled = isEnabled;
      }
    );
  }
}
