import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs';
import { AlertComponent } from 'src/app/common/alert/alert.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private dialog: MatDialog) {}

  errorAlert(message: string) {
    this.dialog.open(AlertComponent, {
      data: {
        type: 'error',
        message: message,
        buttonText: 'Try Again',
      },
    });
  }

  listErrorAlert(listMessage: any) {
    this.dialog.open(AlertComponent, {
      data: {
        type: 'error',
        listMessage: listMessage,
        buttonText: 'Try Again',
      },
    });
  }

  okErrorAlert(message: string) {
    this.dialog.open(AlertComponent, {
      data: {
        type: 'error',
        message: message,
        buttonText: 'Ok',
      },
    });
  }

  successAlert(message?: string) {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        type: 'success',
        message: message,
        buttonText: 'Ok',
      },
    });
    return new Promise((resolve) => {
      dialogRef
        .afterClosed()
        .pipe(
          map((result) => {
            resolve(result);
          })
        )
        .subscribe();
    });
  }

  buttonSuccessAlert(message: string, buttonText: string, cancelBtn?: boolean) {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        type: 'success',
        message: message,
        buttonText: buttonText,
        cancelBtn: cancelBtn,
      },
    });
    return new Promise((resolve) => {
      dialogRef
        .afterClosed()
        .pipe(
          map((result) => {
            resolve(result);
          })
        )
        .subscribe();
    });
  }

  buttonErrorAlert(message: string, buttonText: string, title?: string) {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        type: 'error',
        title: title,
        message: message,
        buttonText: buttonText,
        cancelBtn: true,
      },
    });
    return new Promise((resolve) => {
      dialogRef
        .afterClosed()
        .pipe(
          map((result) => {
            resolve(result);
          })
        )
        .subscribe();
    });
  }
  buttonWarningAlert(message: string, buttonText: string, title?: string) {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        type: 'warning',
        title: title,
        message: message,
        buttonText: buttonText,
        cancelBtn: true,
      },
    });
    return new Promise((resolve) => {
      dialogRef
        .afterClosed()
        .pipe(
          map((result) => {
            resolve(result);
          })
        )
        .subscribe();
    });
  }

  buttonAcceptRejectAlert(message: string, title: string) {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        type: 'error',
        title: title,
        message: message,
        buttonText: 'Accept-Reject',
        cancelBtn: true,
      },
    });
    return new Promise((resolve) => {
      dialogRef
        .afterClosed()
        .pipe(
          map((result) => {
            resolve(result);
          })
        )
        .subscribe();
    });
  }
}
