import { Component, OnInit } from '@angular/core';
import { ApiResponse } from '../model/ApiResponse';
import { NotificationService } from '../services/notification/notification.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-whatsapp',
    templateUrl: './whatsapp.component.html',
    styleUrls: ['./whatsapp.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class WhatsappComponent implements OnInit {
  activeNumber!: number;
  constructor(private service: NotificationService) {}

  ngOnInit(): void {
    this.service.getWhatsAppActiveNumber().subscribe({
      next: (data: ApiResponse) => {
        this.activeNumber = data.body.activeNumber;
      },
      error: (error: any) => {},
    });
  }
}
