import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { ApiResponse } from '../model/ApiResponse';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class SingleLoginInterceptor implements HttpInterceptor {
  private alertShown: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private dialog: MatDialog
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const loginToken = this.authService.getSingleLoginValidateToken();
    const selectedInstitute = this.authService.getSelectedInstitute();
    if (loginToken) {
      request = request.clone({
        headers: request.headers
          .set('loginToken', loginToken)
          .set('instituteId', selectedInstitute)
          .set('Cross-Origin-Embedder-Policy', 'require-corp')
          .set('Cross-Origin-Opener-Policy', 'same-origin'),
      });
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const response = event.body as ApiResponse;
          if (
            (response.status === 401 &&
              response.message == 'User logged in from different platform') ||
            response.status === 403
          ) {
            if (!this.alertShown) {
              this.alertShown = true;
              if (response.status === 401) {
                this.dialog.closeAll();
                this.alertService.errorAlert(
                  'Someone else is logged in your account'
                );
                this.router.navigateByUrl('/login');
                localStorage.clear();
              } else if (response.status === 403) {
                this.dialog.closeAll();
                this.alertService
                  .buttonErrorAlert(
                    'You have been disabled. Please contact Administrator',
                    'Try Again'
                  )
                  .then(() => {
                    window.location.reload();
                  });
                this.router.navigateByUrl('/login');
                localStorage.clear();
              }
            }
          }
        }
      })
    );
  }
}
