<div class="alert-container">
  <div class="alert-icon">
    <i
      class="fa-solid fa-circle-exclamation fa-beat"
      *ngIf="data?.type === 'error'"
      style="color: #ff635f; font-size: 60px"
    ></i>
    <i
      class="fa-solid fa-circle-check fa-beat"
      *ngIf="data?.type === 'success'"
      style="color: #029ef2; font-size: 60px"
    ></i>
    <i
      class="fa-solid fa-exclamation-triangle fa-beat"
      *ngIf="data?.type === 'warning'"
      style="color: #ffce0a; font-size: 60px"
    ></i>
  </div>
  <div>
    @if(data?.title){
    <h2 class="alert-title">{{ data?.title }}</h2>
    } @else {
    <h2 class="alert-heading error" *ngIf="data?.type === 'error'">Error!</h2>
    <h2
      class="alert-heading"
      style="color: #ffce0a"
      *ngIf="data?.type === 'warning'"
    >
      Warning!
    </h2>
    }
  </div>
  <mat-dialog-content class="alert-message" *ngIf="data?.message">{{
    data?.message
  }}</mat-dialog-content>
  <mat-dialog-content class="alert-message" *ngIf="data?.listMessage">
    <div
      style="overflow: auto; max-height: 100%; min-height: 100%; padding: 0px"
    >
      <ul>
        <li
          style="text-align: initial"
          *ngFor="let message of data?.listMessage"
        >
          {{ message }}
        </li>
      </ul>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-center">
    @if (data?.buttonText != 'Accept-Reject') {
    <button
      (click)="closeDialog({ isConfirmed: true })"
      class="action-btn"
      [ngClass]="data?.type === 'error' ? 'error-btn' : 'success-btn'"
    >
      {{ data?.buttonText }}
    </button>
    } @else {
    <button
      (click)="closeDialog({ isConfirmed: true })"
      class="action-btn success-btn"
    >
      Accept
    </button>
    <button
      (click)="closeDialog({ isDenied: true })"
      class="action-btn error-btn"
    >
      Reject
    </button>
    }

    <button
      mat-dialog-close
      *ngIf="data?.cancelBtn"
      class="action-btn cancel-btn"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>
