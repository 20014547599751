import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import {  importProvidersFrom, isDevMode } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { FacebookLoginProvider, GoogleInitOptions, GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from 'src/environments/environment';
import { SingleLoginInterceptor } from './interceptors/single-login.interceptor';
import { RxStompService } from './social-connect/services/rx-stomp.service';
import { rxStompServiceFactory } from './social-connect/socket-config/rx-stomp-service-factory';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { provideServiceWorker } from '@angular/service-worker';

const googleLoginOptions: GoogleInitOptions = {
    oneTapEnabled: false,
};
  
export const appConfig: ApplicationConfig = {
    
  providers: [
    provideRouter(routes), provideClientHydration(), provideAnimations(),
    importProvidersFrom(CommonModule, FormsModule, NgxPaginationModule, MatSnackBarModule),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging()),
    {
        provide: 'SocialAuthServiceConfig', useValue: {
            autoLogin: false,
            providers: [
                {
                    id: GoogleLoginProvider.PROVIDER_ID,
                    provider: new GoogleLoginProvider('244631308103-r7s60kasalav5vgteisopva81k6eiqf0.apps.googleusercontent.com', googleLoginOptions)
                },
                {
                    id: FacebookLoginProvider.PROVIDER_ID,
                    provider: new FacebookLoginProvider('1782500062214042')
                }
            ]
        } as SocialAuthServiceConfig,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: SingleLoginInterceptor,
        multi: true,
    },
    DatePipe,
    {
        provide: RxStompService,
        useFactory: rxStompServiceFactory,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }),
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    })
]
};
