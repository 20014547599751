import { Injectable } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';



@Injectable({
  providedIn: 'root',
})
export class authGuard  {
  constructor(
    private authService: AuthService, private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggin()) {
      return true;
    } else {
       // Store the full URL including query parameters
      const returnUrl = state.url;
      // Redirect to login page with returnUrl
      this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl } });
      return false;
    }
  }
  
}

