import { Routes } from '@angular/router';
import { ModuleGuardGuard } from './guards/module-guard.guard';
import { PlanExpiryGuard } from './guards/plan-expiry.guard';
import { authGuard } from './auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./marketing/marketing-routes').then((m) => m.MarketingRoutes),
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth-routes').then((m) => m.AuthRoutes),
  },
  {
    path: 'teacher',
    loadChildren: () => import('./teacher/teacher-routes').then((m) => m.TeacherRoutes),
    canLoad: [PlanExpiryGuard, ModuleGuardGuard],
    canActivate: [authGuard],
    data: { id: 3 },
  },
  {
    path: 'student',
    loadChildren: () => import('./student/student-routes').then((m) => m.StudentRoutes),
    canLoad: [PlanExpiryGuard, ModuleGuardGuard],
    canActivate: [authGuard],
    data: { id: 2 },
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin-routes').then((m) => m.AdminRoutes),
    canLoad: [PlanExpiryGuard, ModuleGuardGuard],
    canActivate: [authGuard],
    data: { id: 1 },
  },
  {
    path: 'settings',
    loadComponent: () => import('./settings/settings.component').then((m) => m.SettingsComponent),
    canLoad: [ModuleGuardGuard],
  },
  {
    path: 'emailVerification',
    loadComponent: () => import('./email-verification/email-verification.component').then((m) => m.EmailVerificationComponent),
  },
  {
    path: 'master',
    loadChildren: () => import('./subscription/subscription-routes').then((m) => m.SubscriptionRoutes),
    canLoad: [ModuleGuardGuard],
    data: { id: 4 },
  },
  {
    path: 'planExpired',
    loadComponent: () => import('./plan-expiry/plan-expiry.component').then((m) => m.PlanExpiryComponent),
  },
  { 
    path: 'crm', 
    loadChildren: () => import('./crm/crm-routes').then(m => m.CRMRoutes) 
  },
  {
    path: 'error',
    loadComponent: () => import('./common/error-page/error-page.component').then((m) => m.ErrorPageComponent),
  },
  {
    path: '**',
    redirectTo: '/',
    canActivate: [authGuard],
  },
];