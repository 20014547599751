<!-- <app-skeleton-loader *ngIf="skeletonLoader"></app-skeleton-loader> -->
<ng-container>
  <router-outlet></router-outlet>
  <app-loader *ngIf="showLoader"></app-loader>
  <app-whatsapp
    *ngIf="isWhatsAppEnabled && this.subDomainInstitute == 1"
  ></app-whatsapp>
  <a
    href=""
    target="_blank"
    referrerpolicy="origin"
    id="meetingLink"
    style="display: none"
  ></a>
</ng-container>
